<template>
  <div data-app>
    <!-- 1°first ROW -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

 
          <v-card>
             <v-card-title>
              Carrier
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <v-row align="center">
              <v-col cols="12" sm="6">
                <v-subheader v-text="'Carrier por Defecto'"></v-subheader>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex flex-column">
                <span class="text-h6 font-weight-regular black--text"> Carrier:</span>
                <v-select
                    class="ml-2 mr-2"
                    label="Filtro por courier"
                    :items="carrierList"
                    v-model="currentCarrier"
                    item-text="text"
                    item-value="value"
                    @input="fetchData"
                    solo
                ></v-select>
              </v-col>
              </v-row>
            </v-card-text> 

            <v-card-actions dark>
              <v-row  justify-end>
                <v-col cols="6" sm="6" offset-sm=6 justify-end>
                    <v-btn class="btn-starkoms-primary"  dark float="right" @click="saveCourier()">Guardar</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data: () => ({
    inputCourier: "",
    configuration: "DEFAULT_CARRIER",
    carrierList: [],
    currentCarrier: {},
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Configuración de Envios", route: "envio-configuraciones" },
    ]);
    // call carrier Data
    //this.fetchCouriers();
    this.fetchCurrentConfiguration();
    this.fetchCarriers();
  },
  created() {
    // this.initialize();
  },
  methods: {
    initialize() {},
    saveCourier(){

      var vm = this;          
      console.log(vm.currentCarrier);
      this.axios({
        method: "POST",
        url: "shippings/configurations",
        data: {
          name: vm.configuration,
          value: vm.currentCarrier,
        },
      })
        .then((response) => {
          //vm.carrierList = response.data;
          //vm.fetchConfiguration();
           vm.$bvToast.toast(
                `La Configuración predeterminada ha sido actualizada a: `+ vm.currentCarrier,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
            );
        })
        .catch((error) => {
          console.log(error);
        });

    },
    fetchCarriers() {
      var vm = this;
      this.axios({
        method: "GET",
        url: "shippings/couriers/data_select_input",
      })
        .then((response) => {
          console.log(response);
          vm.carrierList = response.data;
          vm.fetchCurrentConfiguration();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCurrentConfiguration() {
      var vm = this;
      this.axios({
        method: "GET",
        url: "shippings/configurations/"+vm.configuration,
      })
        .then((response) => {
          console.log(response);

          let currentCarrier = {
            text : response.data.value,
            value : response.data.value,
          }
          vm.currentCarrier = currentCarrier;
          console.log(vm.currentCarrier);

          //  vm.currentCarrier = {text: "Starken", value: "Starken"};
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
